











import Vue from "vue";
import Component from "vue-class-component";
import { ClassSubject } from "@/models/ClassSubject";
import PermissionsModule from "@/modules/PermissionsModule";
import { getModule } from "vuex-module-decorators";
import { Prop } from "vue-property-decorator";
import { BreadCrumbItem } from "@/models/BreadCrumbItem";
import ClassSubjectShow from "@/views/components/subject/ClassSubjectShow.vue";
import getClassSubject from "@/graphql/classSubjects/getClassSubject.graphql";

@Component({
  metaInfo() {
    return {
      //@ts-ignore
      title: this.classSubject?.denomination ?? "",
    };
  },
  components: { ClassSubjectShow },
  apollo: {
    classSubject: {
      query: getClassSubject,
      watchLoading(isLoading) {
        getModule(PermissionsModule).setIsLoading(isLoading);
      },
      variables() {
        return {
          id: this.class_subject_id,
        };
      },
      error() {
        this.classSubject = null;
        this.$router.push(this.$routes.ERROR_404);
      },
    },
  },
})
export default class CoursePage extends Vue {
  @Prop({ required: true }) class_subject_id!: string;
  protected classSubject: ClassSubject | null = null;

  protected activated() {
    this.$apollo.queries.classSubject.refresh();
  }

  protected get breadcrumbItems(): BreadCrumbItem[] {
    return [
      {
        text:
          this.classSubject?.subject.classLevel.schoolYear.denomination ?? "",
        to: {
          name: this.$routes.YEAR,
          params: {
            year_id: this.classSubject?.subject.classLevel.schoolYear.id ?? "",
          },
        },
        exact: true,
      },
    ];
  }
}
