

















import Component, { mixins } from "vue-class-component";
import { Subject } from "@/models/Subject";
import { Prop, Watch } from "vue-property-decorator";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";

import getClassSubject from "@/graphql/classSubjects/getClassSubject.graphql";
import _ from "lodash";
import { ClassSubject } from "@/models/ClassSubject";
import PageShowButton from "@/views/components/common/buttons/page/PageShowButton.vue";
import ClassSubjectShowData from "@/models/ClassSubjectShowData";
import PageButton from "@/views/components/common/buttons/page/PageButton.vue";
import ClassSubjectPart from "@/models/ClassSubjectPart";
import PropertyArea from "@/views/components/common/PropertyArea.vue";
import { AttributeVO } from "@/models/vos/AttributeVO";
import IAppBar from "@/views/components/common/toolbar/IAppBar.vue";
import TabHashRouting from "@/views/mixins/TabHashRouting.vue";
import PermissionsModule from "@/modules/PermissionsModule";
import { getModule } from "vuex-module-decorators";
import ISubjectService from "@/services/ISubjectService";
import PerformanceReport from "@/models/PerformanceReport";
import classSubjectPartReportsByStudentAndSchoolYear from "@/graphql/classSubjectPartReport/classSubjectPartReportsByStudentAndSchoolYear.graphql";
import ClassSubjectPartReport from "@/models/ClassSubjectPartReport";
import ClassSubjectReportTable from "@/views/components/classSubjectPartReport/ClassSubjectReportTable.vue";

@Component({
  components: {
    ClassSubjectReportTable,
    IAppBar,
    PropertyArea,
    PageButton,
    PageShowButton,
  },
  apollo: {
    class_subject_internal: {
      query: getClassSubject,
      variables() {
        return {
          id: this.class_subject.id,
        };
      },
      update: (data) => data.classSubject,
      skip() {
        return !this.fetchData;
      },
    },
    reports: {
      query: classSubjectPartReportsByStudentAndSchoolYear,
      variables() {
        return {
          class_subject_id: this.class_subject.id,
          student_id: this.studentId,
        };
      },
      update: (data) => data.student.classSubjectPartReports,
    },
  },
})
export default class ClassSubjectShow extends mixins(TabHashRouting) {
  @Prop({ required: true }) class_subject!: ClassSubject;
  @Prop({ default: true }) show_button_visible!: boolean;
  @Prop({ default: false }) fetchData!: boolean;
  @Prop({ default: true }) enableAdminTools!: boolean;
  @lazyInject(TYPES.ISubjectService)
  private subjectService!: ISubjectService;
  private permissionModule: PermissionsModule = getModule(PermissionsModule);

  protected reports: ClassSubjectPartReport[] = [];

  private class_subject_internal: ClassSubjectShowData = _.cloneDeep(
    Object.assign(
      {
        classSubjectParts: [],
        firstTermParts: [],
        secondTermParts: [],
        students: [],
        teachersFirstTerm: [],
        teachersSecondTerm: [],
      },
      this.class_subject
    )
  );

  protected selectedPart: string = "";
  protected subjectPartSearch: string = "";
  protected performanceReports: PerformanceReport[] = [];

  protected terms = [1, 2];

  protected get studentId() {
    return getModule(PermissionsModule).studentId;
  }
  protected get downloadFileName() {
    return `${this.class_subject.denomination} (${this.class_subject.subject.classLevel.schoolYear.denomination})`;
  }

  protected get title() {
    return this.class_subject.denomination;
  }

  protected get titleToolTip(): string {
    return this.$modelFactory.getIdToolTipText(this.class_subject.id);
  }

  @Watch("class_subject")
  onPropChanged(newVal: Subject, oldVal: Subject) {
    if (oldVal !== newVal) {
      this.class_subject_internal = Object.assign(
        this.class_subject_internal,
        _.cloneDeep(this.class_subject)
      );
    }
  }

  protected get properties(): AttributeVO[] {
    const currentSubjectPart: ClassSubjectPart | null =
      this.class_subject_internal.currentClassSubjectPart ?? null;
    const currentTerm = getModule(PermissionsModule).currentTerm;
    const teachers =
      currentTerm === 1
        ? this.class_subject_internal.teachersFirstTerm
        : this.class_subject_internal.teachersSecondTerm;
    return [
      {
        title: this.$tc("classSubjectTeacher", 2),
        value: teachers.map((teacher) => teacher.full_name).join(", "),
      },
      {
        title: this.$t("reqLevelShort").toString(),
        value: this.class_subject_internal.subject?.req_level ?? "",
      },
      {
        title: this.$tc("schoolSubjectType", 1).toString(),
        value: this.subjectService.getSchoolSubjectTypeText(
          this.class_subject_internal.subject?.schoolSubject?.type ?? 0
        ),
      },
      {
        title: this.$t("currentSubjectPart.short"),
        value: currentSubjectPart?.subjectPart?.denomination ?? "--/--",
      },
    ];
  }

  protected get downloadSuffix() {
    return `(${this.class_subject.denomination})`;
  }
}
