
import Vue from "vue";
import Component from "vue-class-component";
import { Location } from "vue-router";

@Component
export default class TabHashRouting extends Vue {
  protected generateTabItemValue(path: string): string | undefined {
    return this.$router
      .resolve({ path: `#${path}` }, undefined, true)
      .href.replace("#", "");
  }

  protected generateTabToValue(path: string): Location {
    return { path: `#${path}` };
  }
}
